export const REGISTER_SUCCESS = 'REGISTER_SUCCESS'
export const USER_LOADED = 'USER_LOADED'
export const AUTH_ERROR = 'AUTH_ERROR'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGOUT = 'LOGOUT'
export const GET_ERORRS = 'GET_ERORRS'
export const GET_PROFILE = 'GET_PROFILE'
export const PROFILE_ERROR = 'PROFILE_ERROR'
export const NEW_OPINION = 'NEW_OPINION'
export const MORE_OPINIONS = 'MORE_OPINIONS'
export const RESTORE_PASSWORD = 'RESTORE_PASSWORD'
export const SET_NUMBER_NOTIFICATIONS = 'SET_NUMBER_NOTIFICATIONS'
export const GET_APPBAR_INFO = 'GET_APPBAR_INFO'
export const GET_USER_LIST = 'GET_USER_LIST'
export const MORE_USERS = 'MORE_USERS'
export const ADD_ANSWER = 'ADD_ANSWER'
export const DELETE_OPINION = 'DELETE_OPINION'
export const GET_MY_PROFILE = 'GET_MY_PROFILE'
export const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS'
export const CLEAR_PROFILE = 'CLEAR_PROFILE'